<!-- 商品列表 -->
<template>
	<div 
	 :lang="$i18n.locale"
	 v-loading.fullscreen="uploadloading"
	 :element-loading-text="$t('tipsInfo.loadingText')"
	 element-loading-spinner="el-icon-loading"
	 element-loading-background="rgba(0, 0, 0, 0.8)"
	 class="app-container">
	  <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
	    <!-- inventory 的数据统计信息 -->
			<el-tab-pane :label="$t('ksaWhInventoryPage.tabOneTitle')" name="first">
				<div class="listHeader">
					<i></i><span>{{$t('ksaWhInventoryPage.tabOnePageTitle')}}</span>
				</div>
				<el-form :inline="true" label-width="auto"
				  style="margin:10px 0 0 10px;height: 40px;">
				  <el-form-item>
						<el-button type="primary" icon="el-icon-refresh" @click="handleRefreshCount">{{$t('commonInfo.refreshBtn')}}</el-button>
				  </el-form-item>
				</el-form>
				<el-card class="box-card cardOption">
					<div slot="header" style="color: #000000;font-size: 24px;" class="clearfix">
					  <span>{{$t('ksaWhInventoryPage.cartonInfo')}}</span>
					</div>
					<div class="text item">
						<p>{{$t('ksaWhInventoryPage.currentCartonOrderCount') + ':'}}
						<!-- <span style="font-size: 24px; color: #0077AA;margin-left: 10px;">{{cartonCount1}}</span> -->
						<animate-number
						ref="cartonCountOne"
						from="0"
						:key="cartonCount1"
						:to="cartonCount1"
						easing="easeOutQuad"
						mode="manual"
						duration="1000"
						style="font-size: 24px; color: #0077AA;margin-left: 10px;"
						>
						</animate-number>
						
						</p>
					</div>
					<div class="text item">
						<p>{{$t('ksaWhInventoryPage.currentCartonCount') + ':'}}
						<!-- <span style="font-size: 24px; color: #0077AA;margin-left: 10px;">{{cartonCount2}}</span> -->
						<animate-number
						ref="cartonCountTwo"
						from="0"
						:key="cartonCount2"
						:to="cartonCount2"
						easing="easeOutQuad"
						mode="manual"
						duration="1000"
						style="font-size: 24px; color: #0077AA;margin-left: 10px;"
						>
						</animate-number>
						</p>
					</div>
				</el-card>
				<el-card class="box-card cardOption">
					<div slot="header" style="color: #000000;font-size: 24px;" class="clearfix">
					  <span>{{$t('ksaWhInventoryPage.skuInfo')}}</span>
					</div>
					<div class="text item">
						<p>{{$t('ksaWhInventoryPage.currentSkuCount') + ':'}}
						  <!-- <span style="font-size: 24px; color: #0077AA;margin-left: 10px;">{{productCount1}}</span> -->
							<animate-number 
							ref="productCountOne"
							from="0"
							:key="productCount1"
							:to="productCount1"
							easing="easeOutQuad"
							mode="manual"
							duration="1000"
							style="font-size: 24px; color: #0077AA;margin-left: 10px;"
							>
							</animate-number>
						</p>
					</div>
					<div class="text item">
						<p>{{$t('ksaWhInventoryPage.currentProductCount') + ':'}}
						<!-- <span style="font-size: 24px; color: #0077AA;margin-left: 10px;">{{productCount2}}</span> -->
						<animate-number
						ref="productCountTwo"
						from="0"
						:key="productCount2"
						:to="productCount2"
						easing="easeOutQuad"
						mode="manual"
						duration="1000"
						style="font-size: 24px; color: #0077AA;margin-left: 10px;"
						>
						</animate-number>
						</p>
					</div>
				</el-card>
			</el-tab-pane>
	    <el-tab-pane :label="$t('ksaWhInventoryPage.tabTwoTitle')" name="second">
				<div class="listHeader">
					<i></i><span>{{$t('ksaWhInventoryPage.tabTwoPageTitle')}}</span>
				</div>
				<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="auto"
				  style="margin:10px 0 0 15px">
				  <el-form-item label prop="companyProductCode">
				    <el-input type="textarea" v-model="companyProductCode" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.clientProductCode')" clearable />
				  </el-form-item>
					<el-form-item label prop="productCode">
					  <el-input v-model="queryParams.productCode" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.productCode')" clearable />
					</el-form-item>
				  <el-form-item>
				    <el-button type="primary" icon="el-icon-search" @click="handleQuery">{{$t('commonInfo.searchBtn')}}</el-button>
						<el-button type="primary" icon="el-icon-refresh" @click="getList">{{$t('commonInfo.refreshBtn')}}</el-button>
						<el-button icon="el-icon-refresh" @click="handleRefresh">{{$t('commonInfo.refreshBtn')}}</el-button>
						
				  </el-form-item>
					<el-form-item>
						<el-button icon="el-icon-download" type="primary" @click="handleExportProduct()">{{$t('ksaWhInventoryPage.exportAllSkuData')}}</el-button>
					</el-form-item>
				</el-form>
				
				<!-- 展示 -->
				<el-table style="margin:0 15px;width: calc(100% - 30px);font-size: 16px;" v-loading="loading" :data="dataList" border>
				  <el-table-column :label="$t('commonInfo.productCode')" align="center" prop="productCode"/>
				  <el-table-column :label="$t('commonInfo.clientProductCode')" align="center" prop="clientProductCode" />
				  <el-table-column :label="$t('commonInfo.productName')" align="center" prop="productNm" />
				  <el-table-column prop="img " :label="$t('commonInfo.photo')" align="center" width="80" >
						<template slot-scope="{ row }">
								<img v-if="row.img   != null && row.img   != ''" :src="row.img  " @click="handleViewImg(row.img)" style="width: 60px;height: 60px;"/>
						</template>
				  </el-table-column>
					<el-table-column :label="$t('commonInfo.productQuantity')" align="center" prop="productQuantity" />
					<el-table-column :label="$t('commonInfo.appointmentQuantity')" align="center" prop="appointmentQuantity" />
				</el-table>
				<div class="pageBottom">
				  <el-pagination
				    @size-change="handleSizeChange"
				    @current-change="handleCurrentChange"
				    :current-page="queryParams.page"
				    :page-sizes="[10, 20, 30, 40]"
				    :page-size="queryParams.limit"
				    layout="total, sizes, prev, pager, next, jumper"
				    :total="total"
				  ></el-pagination>
				</div>
			</el-tab-pane>
			<el-tab-pane :label="$t('ksaWhInventoryPage.tabThreeTitle')" name="third">
				<div class="listHeader">
					<i></i><span>{{$t('ksaWhInventoryPage.tabThreePageTitle')}}</span>
				</div>
				<el-form :model="caseParams" ref="caseForm" :inline="true" label-width="auto"
				  style="margin:10px 0 0 20px">
				  <el-form-item label prop="tobOrderNo">
				    <el-input v-model="caseTobOrderNo" type="textarea" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.orderNoColumn')" clearable />
				  </el-form-item>
					<el-form-item label prop="cartonAwbNo">
					  <el-input v-model="caseAwbNo" type="textarea" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.packageNo')" clearable />
					</el-form-item>
				  <el-form-item>
				    <el-button type="primary" icon="el-icon-search" @click="handleCaseQuery">{{$t('commonInfo.searchBtn')}}</el-button>
						<el-button icon="el-icon-refresh" @click="handleCaseRefresh">{{$t('commonInfo.refreshBtn')}}</el-button>
						<el-button type="primary" @click="handleExportCarton()">{{$t('ksaWhInventoryPage.exportCurrentAllCartonInventoryData')}}</el-button>
				  </el-form-item>
				</el-form>
				
				<!-- 展示 -->
				<el-table style="margin:0 15px;width: calc(100% - 30px);font-size: 16px;" v-loading="caseLoading" :data="caseList" border>
				  <el-table-column :label="$t('commonInfo.orderNoColumn')" align="center" prop="tobOrderNo"/>
				  <el-table-column :label="$t('commonInfo.packageNo')" align="center" prop="cartonAwbNo" />
					<el-table-column :label="$t('ksaWhInventoryPage.putOnTime')" align="center" prop="initTime" width="220px" />
					<el-table-column :label="$t('ksaWhInventoryPage.storageTime')" align="center" prop="remarks" />
				</el-table>
				<div class="pageBottom">
				  <el-pagination
				    @size-change="handleCaseSizeChange"
				    @current-change="handleCaseCurrentChange"
				    :current-page="caseParams.page"
				    :page-sizes="[10, 20, 30, 40]"
				    :page-size="caseParams.limit"
				    layout="total, sizes, prev, pager, next, jumper"
				    :total="caseTotal"
				  ></el-pagination>
				</div>
			</el-tab-pane>
		</el-tabs>
		<el-image-viewer style="z-index: 9999;" v-if="showImg" :on-close="closeImg" :url-list="imgList" />
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

import {downloadStream} from "@/utils/downloadFileStream";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
	ElImageViewer
  },
  data() {
    //这里存放数据
    return {
			activeName:'first',
			uploadloading:false,
			//第一标签
			cartonCount1:0,
			cartonCount2:0,
			productCount1:0,
			productCount2:0,
			// 第二标签
      queryParams: {
				page:1,
				limit:10,
				companyProductCodeList:[],
				productCode:'',
      },
			companyProductCode:'',// 批量检索
			productCode:'',//批量检索
			loading:false,
			dataList:[],
			total:0,
			flag: false,
			showImg:false,
			// 查看图片
			imgList:[],
			// 第三标签
			caseParams: {
				page:1,
				limit:10,
				tobOrderNos:[],
				awbNos:[],
			},
			caseTobOrderNo:'',//搜索参数，可多个搜索
			caseAwbNo:'',//搜索参数，可多个搜索
			caseLoading:false,
			caseList:[],
			caseTotal:0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
	},
  //方法集合
  methods: {
		handleClick(tab, event){
		  if (tab.name == "first") {
		  	this.getInventoryStat();
		  }else if(tab.name == "second"){
		    this.getList();
		  }else if(tab.name == "third"){
		    this.getCaseList();
		  }
		},
		// 刷新统计信息
		handleRefreshCount(){
			this.getInventoryStat();
		},
		//第一标签
		// 获取整箱在库信息
		async getInventoryStat(){
			const res = await this.$http.get("/toborder/wms/carton/stat");
			
			if (res.code === 200) {
			  this.cartonCount1 = res.data.skuCount;
				this.cartonCount2 = res.data.productCount;
				this.$nextTick(() => {
					this.$refs.cartonCountOne.start();
					this.$refs.cartonCountTwo.start();
				})
			} else {
			  this.$message.error(res.msg);
			}
			const resTwo = await this.$http.get("/toborder/wms/product/stat");
			
			if (resTwo.code === 200) {
				this.productCount1 = resTwo.data.skuCount;
				this.productCount2 = resTwo.data.productCount;
				
				this.$nextTick(() => {
					this.$refs.productCountOne.start();
					this.$refs.productCountTwo.start();
				})
				
			} else {
			  this.$message.error(resTwo.msg);
			}
		},
		
		//第二标签
		// 分页条数
		handleSizeChange(size) {
		  this.queryParams.limit = size;
		  this.queryParams.page = 1;
		  this.getList();
		},
		// 分页页数
		handleCurrentChange(page) {
		  this.queryParams.page = page;
		  this.getList();
		},
		// 搜索商品编码显示
    async searchInventory() {
			this.loading = true;
			this.dataList = [];
			var queryCPC = [], queryPC = [];
			if(this.queryParams.companyProductCode != ""){
				queryCPC.push(this.queryParams.companyProductCode);
			}
			if(this.queryParams.productCode != ""){
				queryPC.push(this.queryParams.productCode);
			}
			let query = {
				companyProductCodes:queryCPC,
				productCodes:queryPC,
			};
			
			
			const res = await this.$http.post("/toborder/wms/product/checkProduct",query);
			
			if (res.code === 200) {
			  this.loading = false;
			  this.dataList = res.data;
			} else {
			  this.loading = false;
			  this.$message.error(res.msg);
			}
		},
		// 导出SKU信息
		handleExportProduct(){
			this.uploadloading = true;
			const res = this.$http.post("/toborder/wms/product/exportInventory",null,{
				responseType:'blob',
			}).then(res => {
			    if (res.code != 500) {
			      // window.open("http://awb.dlvd.com/vip_excel/download/" + res.msg);
						downloadStream(res,'xlsx','SKU库存信息')
			    }
					this.uploadloading = false;
			  }).catch(error => {
					this.uploadloading = false;
					this.$message.error(res.msg);
				});
		},
		// 搜索商品编号接口和查询列表接口返回数据一样，但是搜索商品编码接口参数为空，不返回数据，需要判断并调用查询列表接口
		async getList() {
			this.loading = true;
			this.dataList = [];
			const res = await this.$http.post("/toborder/wms/product/getInventoryInformation",this.queryParams);
			
			if (res.code === 200) {
			  this.loading = false;
			  this.dataList = res.data.list;
				this.total = res.data.totalCount;
				this.dataList.forEach(item => {
					let img = JSON.parse(item.productImages);
					item.img = img;
    			})
			} else {
			  this.loading = false;
			  this.$message.error(res.msg);
			}
		},
		
		handleQuery(){
			if(this.companyProductCode != ''){
				this.queryParams.companyProductCodeList = this.strToArr(this.companyProductCode);
			}else{
				this.queryParams.companyProductCodeList = [];
			}
			this.queryParams.page = 1;
			this.getList();
			
		},
		handleRefresh(){
			this.queryParams = {
				page:1,
				limit:10,
				companyProductCodeList:[],
				productCode:'',
			}
			this.getList();
		},
		// 查看图片
		handleViewImg(img){
			this.imgList = [];
			if(img.indexOf(',') == -1){
			  this.imgList.push(img[0])
			}else{
			  this.imgList = img.split(',');
			}
			this.showImg = true;
		},
		//关闭图片
		closeImg() {
			this.showImg = false;
		},
		
		// 第三标签
		// 分页条数
		handleCaseSizeChange(size) {
		  this.caseParams.limit = size;
		  this.caseParams.page = 1;
		  this.getCaseList();
		},
		// 分页页数
		handleCaseCurrentChange(page) {
		  this.caseParams.page = page;
		  this.getCaseList();
		},
		async getCaseList() {
			this.caseLoading = true;
			this.caseList = [];
			const res = await this.$http.post("/toborder/wms/carton/list",this.caseParams);
			
			if (res.code === 200) {
			  this.caseLoading = false;
			  this.caseList = res.data.list;
				this.caseTotal = res.data.totalCount;
			} else {
			  this.caseLoading = false;
			  this.$message.error(res.msg);
			}
		},
		// 导出整箱信息
		handleExportCarton(){
			this.uploadloading = true;
			const res = this.$http.post("/toborder/wms/carton/exportInventory",null,{
				responseType:'blob',
			}).then(res => {
			    if (res.code != 500) {
						downloadStream(res,'xlsx','整箱库存信息')
			      // window.open("http://awb.dlvd.com/vip_excel/download/" + res.msg);
			    }
					this.uploadloading = false;
			  }).catch(error => {
					this.uploadloading = false;
					this.$message.error(res.msg);
				});
		},
		handleCaseQuery(){
			if (this.caseAwbNo != "") {
				this.caseParams.awbNos = this.strToArr(this.caseAwbNo);
			} else {
			  this.caseParams.awbNos = [];
			}
			if (this.caseTobOrderNo != "") {
				this.caseParams.tobOrderNos = this.strToArr(this.caseTobOrderNo);
			} else {
			  this.caseParams.tobOrderNos = [];
			}
			
			this.caseParams.page = 1;
			this.getCaseList();
		},
		handleCaseRefresh(){
			this.caseParams = {
				page:1,
				limit:10,
				tobOrderNos:[],
				awbNos:[],
			}
			this.getCaseList();
		},
  },
  created() {
		this.getInventoryStat();
	},
  mounted() {}
};
</script>
<style scoped>
	.app-container {
		padding: 10px;
	}
	.listHeader{
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	.listHeader i{
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	.listHeader span{
		display: inline-block;
		float: left;
	}
	.pageBottom {
	  padding: 20px 0 0 50px;
  }	
	.cardOption {
		width: calc(50% - 24px);
		float: left;
		margin: 10px;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		::v-deep .el-form-item__label{
			float: right;
		}
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		::v-deep .el-tabs__nav{
			float: right;
		}
	}
</style>
